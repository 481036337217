import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { actions, GlobalModalComponentName, ThunkDispatchType } from "../../../store";
import { CabButton } from "@CabComponents";
import classes from './UpgradeOverlay.module.css';
import { Box, SxProps } from "@mui/material";

// Use Note: Parent element must have position: relative 
// in order for the overlay to cover it correctly

interface ComponentProps {
  show?: boolean;
  message?: string;
  overlaySx?: SxProps;
}

type Props = ComponentProps;

export const UpgradeOverlay = ({show, message,  overlaySx}: 
Props): ReactElement => {

  const dispatch = useDispatch<ThunkDispatchType>();

  const openModal = (componentName: GlobalModalComponentName) =>  
    dispatch(actions.globalModal.openModal(componentName));

  const handleOpenModal = (): void => {
    openModal(GlobalModalComponentName.CABINET_PROMO);
  };
  
  return (
    <div>
      {
        show && (
          <Box className={ overlaySx ? undefined : classes.overlay} sx={overlaySx}>
            <div className={classes.center}>
              <div>
                <div className={classes.message}>
                  {message}
                  <div className={classes.learnMore}>
                    <CabButton
                      buttonType="primary"
                      color="primary"
                      sx={{marginTop: 2}}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOpenModal()}
                    >
                      Learn More
                    </CabButton>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        )
      }
    </div>
  );
};
  
export default UpgradeOverlay;